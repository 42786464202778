// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-designs-tsx": () => import("/opt/build/repo/src/pages/designs.tsx" /* webpackChunkName: "component---src-pages-designs-tsx" */),
  "component---src-pages-illustrations-tsx": () => import("/opt/build/repo/src/pages/illustrations.tsx" /* webpackChunkName: "component---src-pages-illustrations-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("/opt/build/repo/src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-logos-tsx": () => import("/opt/build/repo/src/pages/logos.tsx" /* webpackChunkName: "component---src-pages-logos-tsx" */),
  "component---src-pages-submitted-tsx": () => import("/opt/build/repo/src/pages/submitted.tsx" /* webpackChunkName: "component---src-pages-submitted-tsx" */)
}

